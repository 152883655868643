import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router'
import classnames from 'classnames'
import gravatar from 'gravatar'
import { persistStore, } from 'redux-persist'
import persistConfig from 'config/PersistConfig'
import AccountMenu from 'components/AccountMenu'
import DropdownMenu from 'components/DropdownMenu'
import ModalWrapper from 'components/TwentyThreeModal'
import StackSportsMark from 'assets/stack-mark-red.svg.js'
import StackPayTextLogo from 'assets/stack-pay-text.svg.js'
import _ from 'lodash'
import './AppHeader.scss'

class AppHeaderComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      showMenu: false,
      showSupportModal: false,
      showDeviceManagementOption: false,
      showSubscriptions: false,
      showCustomers: false,
      showCheckout: false,
      currentMID: window.localStorage.getItem('spay-mid'),
      marketNavVisable: false,
      marketNavHeight: 0,
      marketNavOffset: 0
    }
  }

  _updateMarketNav = (entry) => {
    this.setState({
      marketNavVisable: entry.isIntersecting,
      marketNavOffset: entry.boundingClientRect.top
    })
  }

  _marketNavChange = (entry) => {
    this.setState({
      marketNavHeight: entry.contentRect.height
    })
  }

  getMID = (specificMID = null) => {
    let currentMID = null
    if (specificMID) {
      currentMID = parseInt(specificMID, 10)
    } else if (this.props.params.id) {
      currentMID = parseInt(this.props.params.id, 10)
    } else if (this.props.selectedMerchantAccountId) {
      currentMID = this.props.selectedMerchantAccountId
    } else if (this.props.router.params.accountId) {
      currentMID = parseInt(this.props.router.params.accountId, 10)
    } else if (!_.isEmpty(this.props.data.merchants)) {
      currentMID = parseInt(this.props.data.merchants[0].id, 10)
    }
    return currentMID
  }

  componentDidMount() {
    if (!this.props.publicMode) {
      const MerchantId = this.getMID()
      this.setState({
        currentMID: MerchantId
      }, () => {
        this._checkMerchantPermissions()
        this.props.setMerchantAccountId(MerchantId)
      })
      if (this.props.data.profile && this.props.isAuthenticated) {
        this.props.fetchSiteSettings()
      }

      if (_.isEmpty(this.props.data.merchants)) {
        this.props.fetchAccountRequest()
      }
    }

    const el = document.querySelector('#mp-toolbar-host')
    const observer = new IntersectionObserver(
      ([entry]) => {
        this._updateMarketNav(entry)
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    )
    const resizeObserver = new ResizeObserver((entry) => {
      this._marketNavChange(entry[0])
    })


    if (el) {
      observer.observe(el)
      resizeObserver.observe(el)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthenticated && !this.props.publicMode) {
      const { selectedMerchantAccountId } = this.props
      const selectedMidEmpty = !selectedMerchantAccountId || isNaN(selectedMerchantAccountId)

      let currentMID = null
      if (this.props.params.id) {
        currentMID = parseInt(this.props.params.id, 10)
      } else if (!selectedMidEmpty) {
        currentMID = selectedMerchantAccountId
      } else if (this.props.router.params.accountId) {
        currentMID = parseInt(this.props.router.params.accountId, 10)
      } else if (!_.isEmpty(this.props.data.merchants)) {
        currentMID = parseInt(this.props.data.merchants[0].id, 10)
      }

      if (selectedMerchantAccountId !== prevProps.selectedMerchantAccountId
        && selectedMerchantAccountId) {
        this.setState({
          currentMID: selectedMerchantAccountId
        }, () => {
          this._checkMerchantPermissions()
        })
      }

      if ((currentMID && !this.state.currentMID && selectedMidEmpty)
        || (currentMID && !selectedMidEmpty && selectedMerchantAccountId !== currentMID)) {
        this.setState({
          currentMID
        }, () => {
          this.props.setMerchantAccountId(currentMID)
          this._checkMerchantPermissions()
        })
      }

      if (this.props.data.merchants !== prevProps.data.merchants && this.props.data.merchants) {
        this._checkMerchantPermissions()
      }
    }
  }

  _toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu, })
  }

  _toggleSupportModal = () => {
    this.setState({ showSupportModal: !this.state.showSupportModal, })
  }

  _buildSupportModalMessage() {
    const merchants = _.get(this.props, 'data.merchants', null)
    const { search, } = this.props.location
    const appToken = search ? search.substring(1, search.length) : null

    if (merchants !== null) {
      const currentMid = this.state.currentMID ? parseInt(this.state.currentMID, 10)
        : parseInt(this.props.params.id, 10)
      // Find the merchant. If we don't have a current merchant, just get the first one.
      const currentMerchant = currentMid ? merchants.find((m) => parseInt(m.id, 10) === currentMid) : merchants[0]

      return this.state.showSupportModal &&
      (
        <div className="support-modal-content">
          <p>For support, please reach out to us
            at <a href='mailto:stackpaysupport@stacksports.com'>stackpaysupport@stacksports.com</a>.
            For us to quickly and better serve you, please include
            your <strong>Merchant ID</strong> and <strong>{appToken ? 'Application Token' : 'DBA Name'}</strong> in
            your email.  Most emails are responded to within 1 business day but, in some cases, may be delayed.
            Please allow between 1-3 business days for a response.</p>

          {!this.props.publicMode &&
            <React.Fragment>
              {appToken ?
                <div>
                  <p>Merchant ID: <span className='merchant-info-item'>Application In Progress</span></p>
                  <p>Application Token: <span className='merchant-info-item'>{appToken}</span></p>
                </div> :
                <div>
                  <p>Merchant ID: <span className='merchant-info-item'>{currentMerchant.id}</span></p>
                  <p>DBA Name: <span className='merchant-info-item'>{currentMerchant.businessCommonName}</span></p>
                </div>
              }
            </React.Fragment>
          }
        </div>
      )
    }
    return this.state.showSupportModal &&
      (
        <div className="support-modal-content">
          <p>For support, please reach out to us
            at <a href='mailto:stackpaysupport@stacksports.com'>stackpaysupport@stacksports.com</a>.
            For us to quickly and better serve you, please include
            your <strong>Merchant ID</strong> and <strong>{appToken ? 'Application Token' : 'DBA Name'}</strong> in
            your email.  Most emails are responded to within 1 business day but, in some cases, may be delayed.
            Please allow between 1-3 business days for a response.</p>
        </div>
      )
  }

  _checkMerchantPermissions = () => {
    const merchants = this.props.data.merchants
    if (merchants) {
      const currentMerchant = merchants.find((m) => parseInt(m.id, 10) === parseInt(this.state.currentMID, 10))

      const currentUrl = this.props.location.pathname.split('/').filter(Boolean)
      const isStripe = currentMerchant && currentMerchant.processorName === 'Stripe'

      const showDeviceManagementOption = currentMerchant && isStripe && currentMerchant.enablePosDevices === true
      this.setState({ showDeviceManagementOption })

      const showSubscriptions = currentMerchant && currentMerchant.allowSubscriptions === true
      this.setState({ showSubscriptions })

      const showCustomers = currentMerchant && currentMerchant.allowCustomers === true
      this.setState({ showCustomers })

      const showCheckout = currentMerchant && currentMerchant.allowCheckout === true
      this.setState({ showCheckout })

      // adding this: if the user is currently on a page and switches to an account that does not have access to that
      // feature, send them back to the dashboard.
      if (
        (currentUrl[0] === 'device-management' && showDeviceManagementOption === false) ||
        (currentUrl[0] === 'subscriptions-report' && showSubscriptions === false) ||
        (currentUrl[0] === 'customers-report' && showCustomers === false) ||
        (currentUrl[0] === 'checkout' && showCheckout === false) ||
        (currentUrl[0] === 'products' && showCheckout === false)
      ) {
        // you're not supposed to be here...
        this.props.router.push({
          pathname: '/dashboard',
          params: {
            accountId: currentMerchant.id.toString(),
          },
        })
      }
    }
  }

  _logout = (email) => {
    this.props.logout(email)
    if (this.props.store) {
      persistStore(this.props.store, persistConfig, () => {}).purge()
    }
  }

  _openCloseNav = () => {
    const nav = document.getElementById('nav')
    nav.classList.toggle('inactive')
  }

  render() {
    const { publicMode, isAuthenticated, data, settings } = this.props
    const showPrivateComponents = !publicMode && isAuthenticated && !_.isEmpty(data.profile)

    const avatar = data.profile
      ? gravatar.url(data.profile.email, { s: '96', r: 'g', d: 'mp' }, true)
      : ''
    const { showMenu, showDeviceManagementOption,
      currentMID, showSubscriptions, showCustomers, showCheckout, showSupportModal
    } = this.state
    const ContactSupportModal = ModalWrapper(() => this._buildSupportModalMessage())

    window.settings = settings
    window.checkURLforToolbar()

    const hasApplications = _.get(data.profile, 'applicationTokens', []).length > 0

    const hasMerchant = data.merchants && Array.isArray(data.merchants) && data.merchants.length > 0
    const MenuLink = ({ url, icon, label }) => {
      const isCurrentLocation = window.location.pathname.includes(url)
      const isHome = window.location.pathname === '/' || window.location.pathname.includes('dashboard')

      return (
        <Link to={url} onClick={() => this._toggleMenu()} className={classnames(
          { highlight: isCurrentLocation || (label.toLowerCase() === 'home' && isHome) }
        )}>
          <span className='material-icons mi-outlined'>{icon}</span>
          <span className='nav-description'>{label}</span>
        </Link>)
    }

    return (
      <React.Fragment>
        <div className='sticky-header merchant-portal' id='nav'>
          <header className='site-header'>
            <Link to={`/`} className='logo-link'>
              <h1 className='site-logo' id='site-logo'>
                <span className='logo-svg'>
                  <StackSportsMark id='logo-mark'/>
                  <StackPayTextLogo id='logo-text'/>
                </span>
                <span className='site-name'>Stack Pay Merchant Portal</span></h1>
            </Link>
            {showPrivateComponents &&
              <div className='menu-right'>
                <button type='button' className='menu-opener' onBlur={this._onBlur} onClick={() => this._toggleMenu()}>
                  <span className='material-icons opener'>menu</span>
                </button>
                <nav className={classnames('main-nav', { visible: showMenu === true })}>
                  <button type='button' className='menu-closer' onClick={() => this._toggleMenu()}>
                    <span className='material-icons'>close</span>
                  </button>
                  {hasApplications &&
                    <MenuLink url={`/pending-applications`} icon='work_history' label='Applications'/>
                  }
                  {hasMerchant &&
                    <MenuLink url={`/dashboard/${currentMID}`} icon='home' label='Home'/>
                  }
                  {data.merchants && Array.isArray(data.merchants) && data.merchants.length > 1 &&
                    (<MenuLink url={`/account/info`} icon='account_box' label='My Account'/>)
                  }
                  {data.merchants && Array.isArray(data.merchants) && data.merchants.length === 1 &&
                    (<MenuLink
                      url={`/account/merchants/${data.merchants[0].id}`}
                      icon='account_box'
                      label='My Account'
                    />)
                  }
                  {hasMerchant && <MenuLink url={`/transaction-detail/${currentMID}`} icon='attach_money' label='Transactions'/>}
                  {hasMerchant && <MenuLink url={`/payouts/${currentMID}`} icon='account_balance' label='Payouts'/>}
                  {hasMerchant && <MenuLink url={`/disputes/${currentMID}`} icon='credit_card_off' label='Disputes'/>}
                  {hasMerchant && showSubscriptions &&
                    <MenuLink
                      url={`/subscriptions-report/${currentMID}`}
                      icon='currency_exchange'
                      label='Subscriptions'
                    />
                  }
                  {hasMerchant && showCustomers &&
                    <MenuLink
                      url={`/customers-report/${currentMID}`}
                      icon='contacts'
                      label='Customers'/>
                  }
                  {hasMerchant && showCheckout &&
                    <MenuLink
                      url={`/products/${currentMID}`}
                      icon='inventory_2'
                      label='Product Catalog'/>
                  }
                  {hasMerchant && showCheckout &&
                    <MenuLink
                      url={`/checkout/${currentMID}/payment-links`}
                      icon='shopping_cart'
                      label='Payment Links'/>
                  }
                  {(data.profile.isMerchantAdmin && showDeviceManagementOption) &&
                    <MenuLink url={`/device-management`} icon='devices_other' label='Devices'/>
                  }
                  {hasMerchant && <MenuLink url={`/users/${currentMID}`} icon='people' label='Users'/>}
                  <div className='logout-nav-link'>
                    <button type='button' className='logout' onClick={() => {
                      this._logout(_.get(data, 'profile.email', null))
                    }}>
                      <span className='material-icons mi-outlined'>power_settings_new</span>
                      <span className='nav-description'>Logout</span>
                    </button>
                  </div>
                </nav>
              </div>
            }
            <button
              type='button'
              id='nav-toggle-holder'
              className='nav-toggle-holder hide-on-mobile'
              onClick={() => {
                this._openCloseNav()
              }}>
              <div className='semi-sphere'></div>
              <div id='nav-toggle' className='nav-toggle'></div>
              <span className='material-icons'>arrow_back_ios</span>
            </button>
          </header>
          <div
            className='account-nav'
          >
            {showPrivateComponents &&
              <h2 className='greeting'>
                <div className='message-holder'>
                  <span className='message'>Welcome Back, </span>
                  <div className='account-name'>
                    {`${data.profile.firstName} ${data.profile.lastName}` || 'User'}
                  </div>
                </div>
                <span
                  className='avatar'
                  title='Your Global Avatar from Gravatar.com'
                  style={{ backgroundImage: `url(${avatar})` }}
                />
              </h2>
            }
            <button type='button' className='toggleSupportModal' onClick={() => {
              this._toggleSupportModal()
            }}>
              <span className='button-label'>Contact Support</span>
              <span className='material-icons mi-outlined'>contact_support</span>
            </button>
            <button type='button' className='logout' onClick={() => {
              this._logout(_.get(data, 'profile.email', null))
            }}>
              <span className='button-label'>Logout</span>
              <span className='material-icons'>power_settings_new</span>
            </button>
          </div>
        </div>
        { showSupportModal &&
          <ContactSupportModal fixed title="Contact Support" onRequestClose={() => {
            this._toggleSupportModal()
          }} />
        }

      </React.Fragment>
    )
  }
}

AppHeaderComponent.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default withRouter(AppHeaderComponent)
