import React, { Component } from 'react';

class StackOwnedBanner extends Component {
  render() {
    const { merchant } = this.props;
    if (merchant.stackOwned) {
      return (
        <div className="alert-banner-wrapper">
          <div className="alert-banner">
            <div className="banner-header">
            <div className='icon icon-alert'>
              <span className='material-icons'>warning_amber</span>
              </div>
              <div className="alert-label">
                <span className="alert-headline">Alert: This is a Stack Sports owned merchant account.</span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default StackOwnedBanner;

