import React, { Component } from 'react'
import Loader from 'components/Loader'
import _ from 'lodash'
import './MerchantView.scss'
import MerchantHeader from '../MerchantHeader'
import FundingHoldBanner from '../FundingHoldBanner'
import StackOwnedBanner from '../StackOwnedBanner'
import CTABanner from '../CTABanner'
import MerchantTabs from '../MerchantTabs'
import MerchantStatus from '../MerchantStatus'
import MerchantMenu from '../MerchantMenu'
import AlertBanner from '../AlertBanner'

class MerchantView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canViewMerchant: false,
      merchantStatus: null,
    }
  }

  componentDidMount() {
    const {
      routeParams,
      fetchMerchantProfile,
      fetchFullRatesList,
      fetchMerchantHash,
      fetchMerchantAlerts,
      fetchAdminRoles,
      adminRoles,
    } = this.props

    fetchMerchantProfile(routeParams.id)
    fetchFullRatesList()
    fetchMerchantHash(routeParams.id)
    fetchMerchantAlerts(routeParams.id)

    if (!adminRoles) {
      fetchAdminRoles()
    } else {
      this.setState({
        canViewMerchant: Object.keys(adminRoles).includes('can-view-merchant-info'),
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { adminRoles, merchantProfile, accountUpdated, routeParams } = this.props

    if (adminRoles && adminRoles !== prevProps.adminRoles) {
      this.setState({
        canViewMerchant: Object.keys(adminRoles).includes('can-view-merchant-info'),
      })
    }

    if (!_.isEmpty(merchantProfile) && merchantProfile !== prevProps.merchantProfile) {
      this.setState({ merchantStatus: merchantProfile.status })
    }

    if (accountUpdated && !prevProps.accountUpdated) {
      setImmediate(() => {
        this.props.showSuccess('Merchant updated', 'The merchant account has been updated')
      })
      this.props.fetchMerchantProfile(routeParams.id)
      this.props.fetchFullRatesList()
      this.props.fetchMerchantHash(routeParams.id)
      this.props.fetchMerchantAlerts(routeParams.id)
    }
  }

  _onUpdateStatus = (status, statusCode, reason = null) => {
    this.props.updateMerchantStatus(this.props.routeParams.id, status, reason)
    this.setState({ merchantStatus: statusCode })
  }

  _onSendBeneficialData = (data) => {
    this.props.sendBeneficialData(this.props.routeParams.id, data)
  }

  _submitUpdateFundingHold = (data) => {
    this.props.updateFundingHold(this.props.routeParams.id, data)
  }

  _submitRevertProcessor = (data) => {
    this.props.revertProcessor(this.props.routeParams.id, data)
  }

  _submitTOSAcceptance = () => {
    this.props.acceptTOS(this.props.routeParams.id)
  }

  _onUpdateMerchant = () => {
    const {
      routeParams,
      fetchMerchantProfile,
      fetchFullRatesList,
      fetchMerchantHash,
      fetchMerchantAlerts,
    } = this.props

    fetchMerchantProfile(routeParams.id)
    fetchFullRatesList()
    fetchMerchantHash(routeParams.id)
    fetchMerchantAlerts(routeParams.id)
  }

  render() {
    const {
      isFetching,
      merchantProfile,
      fullRatesList,
      merchantHashValue,
      children,
      adminRoles,
      merchantAlerts,
      error,
      isSubmitting,
    } = this.props

    const { canViewMerchant, merchantStatus } = this.state
    const activeTab = this.props.location.pathname.split('/').pop()
    return (
      <div className="merchant-profile-view">
        {isFetching ? (
          <Loader />
        ) : (
          <div className="container-fluid">
            {!canViewMerchant ? (
              <div>Not Allowed</div>
            ) : (
              <div>
                <div className="page-banners">
                  <StackOwnedBanner merchant={merchantProfile} />
                  <AlertBanner merchantAlerts={merchantAlerts} />
                  <FundingHoldBanner merchant={merchantProfile} />
                  <CTABanner merchant={merchantProfile} />
                </div>
                <div className="merchant-top-container">
                  <div className="merchant-top-bar">
                  <div className="merchant-top-left">
                      <MerchantHeader merchant={merchantProfile} />
                    </div>
                    <div className="merchant-top-middle">
                      {merchantProfile && merchantProfile.createdAt && (
                        <span className="merchant-created">
                          Created: {merchantProfile.createdAt}
                        </span>
                      )}
                    </div>
                    <div className="merchant-top-right">
                    {merchantProfile && merchantProfile.businessCommonName && (
                          <h2 className="merchant-business-common">
                            {merchantProfile.businessCommonName}
                          </h2>
                        )}
                      <div className="merchant-status-row">
                        {(merchantStatus || merchantStatus === 0) && (
                          <MerchantStatus
                            merchant={merchantProfile}
                            merchantStatus={merchantStatus}
                            className="merchant-status-component"
                          />
                        )}
                      </div>
                      <MerchantMenu
                      merchant={merchantProfile}
                      adminRoles={adminRoles}
                      rates={fullRatesList}
                      merchantStatus={merchantStatus}
                      onUpdateStatus={(status, statusCode, reason = null) => {
                        this._onUpdateStatus(status, statusCode, reason)
                      }}
                      onSendBeneficialData={(data) => {
                        this._onSendBeneficialData(data)
                      }}
                      beneficialData={this.props.beneficialData}
                      merchantHash={merchantHashValue}
                      error={this.props.error}
                      isSubmitting={this.props.isSubmitting}
                      submitUpdateFundingHold={(data) => { this._submitUpdateFundingHold(data) }}
                      submitRevertProcessor={(data) => { this._submitRevertProcessor(data) }}
                      submitTOSAcceptance={() => { this._submitTOSAcceptance() }}
                      />
                    </div>
                  </div>
                  <div className="merchant-tabs-row">
                    <MerchantTabs
                      merchant={merchantProfile}
                      adminRoles={adminRoles}
                    />
                  </div>
                </div>
                <div className={`merchant-details-section content active-tab-${activeTab}`}>
                  {React.cloneElement(children, {
                    isFetching,
                    merchant: merchantProfile,
                    merchantAlerts,
                    onUpdateMerchant: this._onUpdateMerchant,
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default MerchantView
