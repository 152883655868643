import React, { Component } from 'react'
import utils from 'utils/utils'
import moment from 'moment'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';

class FundingHoldBanner extends Component {
  render() {
    const { merchant } = this.props

    const fundingHold = merchant.merchantBalance ? merchant.merchantBalance : ''
    const isProPayHold = fundingHold.sweepEnabled === 'N' && merchant.processorName === 'ProPay'
    const isStripeHold = merchant.hasFundingHold === true && merchant.processorName === 'Stripe'
    const balance = fundingHold.availableBalance ? utils.commafy(fundingHold.availableBalance) : '0.00'
    const setByDate = moment(merchant.holdFirstReportedDate).utc().format('l')

    let holdReason = null
    if (merchant.fundingHolds && merchant.processorName === 'Stripe') {
      if (merchant.fundingHolds.suspiciousActivity) {
        holdReason = 'Suspicious Activity'
      } else if (merchant.fundingHolds.confirmedFraud) {
        holdReason = 'Confirmed Fraud'
      } else if (merchant.fundingHolds.limitsIssue) {
        holdReason = 'Limits Issue'
      } else if (merchant.fundingHolds.invalidBankAccount) {
        holdReason = 'Invalid Bank Account'
      } else if (merchant.fundingHoldDesc) {
        holdReason = `Other - ${merchant.fundingHoldDesc}`
      } else if (merchant.hasCTA) {
        holdReason = 'Other - User needs to complete active CTA'
      } else {
        holdReason =
          `Other - Contact processor (${merchant.processorName}) for details on why the account is on a funding hold`
      }
    } else if (merchant.fundingHoldSetBy === 'processor' || merchant.processorName === 'ProPay') {
      holdReason =
        `Other - Contact processor (${merchant.processorName}) for details on why the account is on a funding hold`
    }

    const setByName = (merchant.fundingHoldSetBy === 'processor' || merchant.processorName === 'ProPay')
      ? `Processor (${merchant.processorName})`
      : (merchant.fundingHoldSetByUser || merchant.fundingHoldSetBy)

    return (
      <div className="alert-banner-wrapper">
        {(isProPayHold || isStripeHold) &&
          <div className='alert-banner'>
            <div className="banner-header">
              <div className='icon-alert'>
                <span className="material-icons">warning_amber</span>
              </div>
              <div className='alert-label'>
                <span className='alert-headline'>Alert: Account is on Funding Hold</span>
                <span className='alert-details'>
                  {(merchant.fundingHoldSetBy && setByDate)
                    ? <React.Fragment>
                        Set by: <strong>{setByName}</strong> on <strong>{setByDate}</strong>.<br/>
                      </React.Fragment>
                    : <React.Fragment>Set on <strong>{setByDate}</strong><br/></React.Fragment>
                  }
                  {(holdReason) && <React.Fragment>Reason: <strong>{holdReason}</strong></React.Fragment>}
                </span>
              </div>
              <div className='margin-left-auto flex-items-top'>
                <div className='flex-items-center'>
                  <span className='alert-balance'>Held Balance: ${balance}</span>
                  <Tooltip
                    className='tooltip-wrapper'
                    title="Funding Holds are updated once daily in StackPay.
                    Please note that if the gateway has removed the Funding Hold this message will display until
                    the next day when StackPay can confirm the hold has been removed from gateway.
                    The balance displayed does not include any pending balance."
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default FundingHoldBanner
