import React, { Component } from 'react'
import { Link, withRouter } from 'react-router'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

class MerchantHeader extends Component {
  render() {
    return (
      <div className="merchant-profile-header">
        <div className="arrow-icon">
          <Link to="/admin/merchants">
            <KeyboardArrowLeftIcon />
          </Link>
        </div>
        <h3 className="headerTitle">
          <Link to="/admin/merchants">Merchants</Link>
        </h3>
      </div>
    )
  }
}

export default withRouter(MerchantHeader)
