import React, { Component } from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import _ from 'lodash'
import Tooltip from '@mui/material/Tooltip'
import HistoryIcon from '@mui/icons-material/History'
import { MERCHANT_STATUSES } from '../../constants'

const getStatusClass = (status) => {
  switch(status) {
    case -1: return "error";
    case 0: return "disabled";
    case 1: return "active";
    case 2: return "submitted";
    case 3: return "verifying";
    case 4: return "info-needed";
    case 5: return "rejected";
    case 11: return "in-review";
    case 33: return "closed-pending";
    case 66: return "pending";
    case 99: return "denied";
    case 133: return "closed";
    default: return "";
  }
}

class MerchantStatus extends Component {
  constructor(props) {
    super(props)
    this.state = { copied: false }
  }

  handleCopyId = () => {
    navigator.clipboard.writeText(this.props.merchant.id)
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 2000)
  }

  render() {
    const { merchant, merchantStatus } = this.props
    const currentStatus = _.find(MERCHANT_STATUSES, { status: merchantStatus })
    return (
      <div className='merchant-status-info'>
        <p className={`merchant-status-pill merchant-status-${getStatusClass(currentStatus.status)}`}>
          {merchant.processorName === 'Stripe'
            ? currentStatus.label.replace(' - Pending', '')
            : currentStatus.label}
          <Tooltip title={`Status since ${moment(merchant.currentStatusDate || merchant.accountCreateDate).local().format('MM/DD/YYYY')}`}>
            <HistoryIcon className="status-icon" />
          </Tooltip>
        </p>
        <p>
          <strong>ID:</strong>
          <span className="mid" onClick={this.handleCopyId} title="Click to copy" style={{ cursor: 'pointer', marginLeft: '4px' }}>
            {this.state.copied ? 'Copied' : merchant.id}
          </span>
        </p>
        <p><strong>Created: </strong>{moment(merchant.accountCreateDate).local().format('MM/DD/YYYY')}</p>
      </div>
    )
  }
}

export default withRouter(MerchantStatus)
