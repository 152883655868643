import React, { Component } from 'react'
import moment from 'moment'
import utils from 'utils/utils'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';

class CtaBanner extends Component {
  render() {
    const { merchant } = this.props
    const ctaDate = moment(merchant.ctaAssignedAt).format('l')

    return (
      <div className="alert-banner-wrapper">
        {merchant.hasCTA &&
          <div className='alert-banner'>
            <div className="banner-header">
              <div className='icon icon-alert'>
                <span className="material-icons">warning_amber</span>
              </div>
              <div className='alert-label'>
                <span className='alert-headline'>Alert: Account has an active CTA set</span>
                <span className='alert-details'>
                  {ctaDate && <React.Fragment>Set on <strong>{ctaDate}</strong><br/></React.Fragment>}
                  {merchant.ctaTitle &&
                    <React.Fragment>CTA Name: <strong>{merchant.ctaTitle}</strong></React.Fragment>}
                </span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default CtaBanner
