import React, { Component } from 'react'
import { Link, withRouter } from 'react-router'
import classnames from 'classnames'
import _ from 'lodash'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

class MerchantTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canViewDocuments: false,
      isMobile: false,
      overflowAnchor: null
    }
  }

  componentDidMount() {
    if (this.props.adminRoles) {
      this.setState({
        canViewDocuments: Object.keys(this.props.adminRoles).includes('can-view-documents')
      })
    }
    this.setState({ isMobile: window.innerWidth <= 768 })
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 })
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminRoles && this.props.adminRoles !== prevProps.adminRoles) {
      this.setState({
        canViewDocuments: Object.keys(this.props.adminRoles).includes('can-view-documents')
      })
    }
  }

  isActive = (name) => {
    let pageKey = this.props.location.pathname.split('/').pop()
    if (_.isFinite(parseInt(pageKey, 10)) || pageKey === '') {
      pageKey = 'profile'
    }
    return pageKey === name
  }

  handleOverflowMenuOpen = (event) => {
    this.setState({ overflowAnchor: event.currentTarget })
  }

  handleOverflowMenuClose = () => {
    this.setState({ overflowAnchor: null })
  }

  render() {
    const mid = this.props.params.id
    const { canViewDocuments, isMobile, overflowAnchor } = this.state
    const tabs = [
      { name: 'profile', label: 'General' },
      { name: 'limits', label: 'Services' },
      { name: 'contact', label: 'Contact' },
      { name: 'banking', label: 'Banking' },
      { name: 'users', label: 'Users' },
      { name: 'notifications', label: 'Notifications' }
    ]
    if (canViewDocuments) {
      tabs.push({ name: 'documents', label: 'Documents' })
    }
    tabs.push({ name: 'history', label: 'History' })
    tabs.push({ name: 'misc', label: 'Misc.' })
    tabs.push({ name: 'volume', label: 'Volume' })

    const visibleCount = isMobile ? 3 : tabs.length
    const visibleTabs = tabs.slice(0, visibleCount)
    const overflowTabs = tabs.slice(visibleCount)
    const overflowOpen = Boolean(overflowAnchor)

    return (
      <div className='tabs-row'>
        <div className='tabs'>
          {visibleTabs.map(tab => (
            <Link
              key={tab.name}
              to={`/admin/merchants/${mid}/${tab.name}`}
              className={classnames('tab-button', { active: this.isActive(tab.name) })}
            >
              {tab.label}
            </Link>
          ))}
        </div>
        {isMobile && (
          <div
            className='overflow-icon'
            onClick={overflowOpen ? this.handleOverflowMenuClose : this.handleOverflowMenuOpen}
          >
            {overflowOpen ? <CloseIcon /> : <MoreVertIcon />}
          </div>
        )}
        <Menu
          anchorEl={overflowAnchor}
          keepMounted
          open={overflowOpen}
          onClose={this.handleOverflowMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          className='merchant-tabs-menu'
        >
          {(isMobile ? tabs : overflowTabs).map(tab => (
            <MenuItem key={tab.name} onClick={this.handleOverflowMenuClose}>
              <Link
                to={`/admin/merchants/${mid}/${tab.name}`}
                className={classnames('tab-button', { active: this.isActive(tab.name) })}
              >
                {tab.label}
              </Link>
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

export default withRouter(MerchantTabs)
