import React, { Component } from 'react'
import { ALERT_TYPES } from '../../constants'
import './AlertBanner.scss'

class AlertBanner extends Component {
  render() {
    const { merchantAlerts } = this.props

    return (
      <div className="alert-banner-wrapper">
        {merchantAlerts &&
          merchantAlerts.map((alert, idx) => {
            const alertTypeConfig = ALERT_TYPES[alert.type]
            if (alert.is_active) {
              return (
                <div className={`alert-banner ${alert.type}`} key={idx}>
                  <div className="banner-header">
                    <div className="icon-alert">
                      <span className="material-icons">
                        {alertTypeConfig.icon}
                      </span>
                    </div>
                    <div className="alert-label">
                      <span className="alert-headline">
                        {alert.title}: {alert.message}
                      </span>
                    </div>
                  </div>
                </div>
              )
            }
            return null
          })}
      </div>
    )
  }
}

export default AlertBanner
