export const TYPES = {
  USA: [
    { value: 'NP', label: 'Non-Profit' },
    { value: 'I', label: 'Sole-Proprietorship' },
    {
      value: 'O',
      label: 'Company',
      tooltipValues: ['LLC', 'Corporation', 'LTD', 'Partnership']
    }
  ],

  CAN: [
    { value: 'NP', label: 'Non-Profit' },
    { value: 'I', label: 'Sole-Proprietorship' },
    {
      value: 'O',
      label: 'Company',
      tooltipValues: ['Corporation', 'Cooperative', 'Partnership']
    }
  ],

  GBR: [
    { value: 'NP', label: 'Non-Profit' },
    { value: 'I', label: 'Sole-Proprietorship' },
    {
      value: 'O',
      label: 'Company',
      tooltipValues: ['LLC', 'Corporation', 'LTD', 'Partnership']
    }
  ],

  AUS: [
    { value: 'NP', label: 'Non-Profit' },
    { value: 'I', label: 'Sole-Trader' },
    {
      value: 'O',
      label: 'Company',
      tooltipValues: ['Company', 'Trust', 'Partnership']
    }
  ],

  NZL: [
    { value: 'NP', label: 'Non-Profit' },
    { value: 'I', label: 'Sole-Trader' },
    {
      value: 'O',
      label: 'Company',
      tooltipValues: ['Company', 'Trust', 'Partnership']
    }
  ]
}

export const DISPLAY_BUSINESS_TYPES = {
  USA: [
    { value: 'NP', label: 'Non-Profit', },
    { value: 'I', label: 'Sole-Proprietorship', },
    { value: 'O', label: 'Company' }
  ],

  CAN: [
    { value: 'NP', label: 'Non-Profit', },
    { value: 'I', label: 'Sole-Proprietorship', },
    { value: 'O', label: 'Company', },
  ],

  GBR: [
    { value: 'NP', label: 'Non-Profit', },
    { value: 'I', label: 'Sole-Proprietorship', },
    { value: 'O', label: 'Company' }
  ],

  AUS: [
    { value: 'NP', label: 'Non-Profit', },
    { value: 'I', label: 'Sole-Trader', },
    { value: 'O', label: 'Company', },
  ],

  NZL: [
    { value: 'NP', label: 'Non-Profit', },
    { value: 'I', label: 'Sole-Trader', },
    { value: 'O', label: 'Company', },
  ],
}

export const COUNTRIES = [
  { label: 'USA', value: 'USA', },
  { label: 'Canada', value: 'CAN', },
  { label: 'Australia', value: 'AUS', },
  { label: 'New Zealand', value: 'NZL', },
  { label: 'United Kingdom', value: 'GBR', },
]

export const CANADA_PROVINCES = [
  { label: 'Alberta', value: 'AB', },
  { label: 'British Columbia', value: 'BC', },
  { label: 'Manitoba', value: 'MB', },
  { label: 'New Brunswick', value: 'NB', },
  { label: 'Newfoundland and Labrador', value: 'NL', },
  { label: 'Northwest Territories', value: 'NT', },
  { label: 'Nova Scotia', value: 'NS', },
  { label: 'Nunavut', value: 'NU', },
  { label: 'Yukon', value: 'YT', },
  { label: 'Ontario', value: 'ON', },
  { label: 'Prince Edward Island', value: 'PE', },
  { label: 'Quebec', value: 'QC', },
  { label: 'Saskatchewan', value: 'SK', },
]

export const USA_STATES = [
  { label: 'Alabama', value: 'AL', },
  { label: 'Alaska', value: 'AK', },
  { label: 'Arizona', value: 'AZ', },
  { label: 'Arkansas', value: 'AR', },
  { label: 'California', value: 'CA', },
  { label: 'Colorado', value: 'CO', },
  { label: 'Connecticut', value: 'CT', },
  { label: 'Delaware', value: 'DE', },
  { label: 'Florida', value: 'FL', },
  { label: 'Georgia', value: 'GA', },
  { label: 'Hawaii', value: 'HI', },
  { label: 'Idaho', value: 'ID', },
  { label: 'Illinois', value: 'IL', },
  { label: 'Indiana', value: 'IN', },
  { label: 'Iowa', value: 'IA', },
  { label: 'Kansas', value: 'KS', },
  { label: 'Kentucky', value: 'KY', },
  { label: 'Louisiana', value: 'LA', },
  { label: 'Maine', value: 'ME', },
  { label: 'Maryland', value: 'MD', },
  { label: 'Massachusetts', value: 'MA', },
  { label: 'Michigan', value: 'MI', },
  { label: 'Minnesota', value: 'MN', },
  { label: 'Mississippi', value: 'MS', },
  { label: 'Missouri', value: 'MO', },
  { label: 'Montana', value: 'MT', },
  { label: 'Nebraska', value: 'NE', },
  { label: 'Nevada', value: 'NV', },
  { label: 'New Hampshire', value: 'NH', },
  { label: 'New Jersey', value: 'NJ', },
  { label: 'New Mexico', value: 'NM', },
  { label: 'New York', value: 'NY', },
  { label: 'North Carolina', value: 'NC', },
  { label: 'North Dakota', value: 'ND', },
  { label: 'Ohio', value: 'OH', },
  { label: 'Oklahoma', value: 'OK', },
  { label: 'Oregon', value: 'OR', },
  { label: 'Pennsylvania', value: 'PA', },
  { label: 'Puerto Rico', value: 'PR', },
  { label: 'Rhode Island', value: 'RI', },
  { label: 'South Carolina', value: 'SC', },
  { label: 'South Dakota', value: 'SD', },
  { label: 'Tennessee', value: 'TN', },
  { label: 'Texas', value: 'TX', },
  { label: 'Utah', value: 'UT', },
  { label: 'Vermont', value: 'VT', },
  { label: 'Virginia', value: 'VA', },
  { label: 'Washington', value: 'WA', },
  { label: 'West Virginia', value: 'WV', },
  { label: 'Wisconsin', value: 'WI', },
  { label: 'Wyoming', value: 'WY', },
  { label: 'District of Columbia', value: 'DC', },
]

export const AUSTRALIA_STATES = [
  { label: 'Australian Capital Territory', value: 'ACT', },
  { label: 'New South Wales', value: 'NSW', },
  { label: 'Northern Territory', value: 'NT', },
  { label: 'Queensland', value: 'QLD', },
  { label: 'South Australia', value: 'SA', },
  { label: 'Tasmania', value: 'TAS', },
  { label: 'Victoria', value: 'VIC', },
  { label: 'Western Australia', value: 'WA', },
]

export const NEW_ZEALAND_STATES = [
  { label: 'Auckland', value: 'AUK', },
  { label: 'Bay of Plenty', value: 'BOP', },
  { label: 'Canterbury', value: 'CAN', },
  { label: 'Chatham Islands Territory', value: 'CIT', },
  { label: 'Gisborne', value: 'GIS', },
  { label: 'Greater Wellington', value: 'WGN', },
  { label: 'Hawkes Bay', value: 'HKB', },
  { label: 'Manawatu-Whanganui', value: 'MWT', },
  { label: 'Marlborough', value: 'MBH', },
  { label: 'Nelson', value: 'NSN', },
  { label: 'Northland', value: 'NTL', },
  { label: 'Otago', value: 'OTA', },
  { label: 'Southland', value: 'STL', },
  { label: 'Taranaki', value: 'TKI', },
  { label: 'Tasman', value: 'TAS', },
  { label: 'Waikato', value: 'WKO', },
  { label: 'West Coas', value: 'WTC', },
]

export const PAYOUT_FREQUENCY_OPTIONS = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
]

export const PAYOUT_FREQUENCY_WEEKLY_OPTIONS = [
  {
    value: 'monday',
    label: 'Monday',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
  },
  {
    value: 'thursday',
    label: 'Thursday',
  },
  {
    value: 'friday',
    label: 'Friday',
  },
]

export const PAYOUT_FREQUENCY_MONTHLY_OPTIONS = [
  {
    value: '1',
    label: '1st',
  },
  {
    value: '2',
    label: '2nd',
  },
  {
    value: '3',
    label: '3rd',
  },
  {
    value: '4',
    label: '4th',
  },
  {
    value: '5',
    label: '5th',
  },
  {
    value: '6',
    label: '6th',
  },
  {
    value: '7',
    label: '7th',
  },
  {
    value: '8',
    label: '8th',
  },
  {
    value: '9',
    label: '9th',
  },
  {
    value: '10',
    label: '10th',
  },
  {
    value: '11',
    label: '11th',
  },
  {
    value: '12',
    label: '12th',
  },
  {
    value: '13',
    label: '13th',
  },
  {
    value: '14',
    label: '14th',
  },
  {
    value: '15',
    label: '15th',
  },
  {
    value: '16',
    label: '16th',
  },
  {
    value: '17',
    label: '17th',
  },
  {
    value: '18',
    label: '18th',
  },
  {
    value: '19',
    label: '19th',
  },
  {
    value: '20',
    label: '20th',
  },
  {
    value: '21',
    label: '21st',
  },
  {
    value: '22',
    label: '22nd',
  },
  {
    value: '23',
    label: '23rd',
  },
  {
    value: '24',
    label: '24th',
  },
  {
    value: '25',
    label: '25th',
  },
  {
    value: '26',
    label: '26th',
  },
  {
    value: '27',
    label: '27th',
  },
  {
    value: '28',
    label: '28th',
  },
  {
    value: '29',
    label: '29th',
  },
  {
    value: '30',
    label: '30th',
  },
  {
    value: '31',
    label: '31st',
  },
]

export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'checking',
    label: 'Checking'
  },
  {
    value: 'savings',
    label: 'Savings'
  }
]

export const MERCHANT_STATUSES = [
  {
    status: -1,
    label: 'Error',
    color: '#b00211',
  },
  {
    status: 0,
    label: 'Disabled',
    color: '#c4c8c2',
  },
  {
    status: 1,
    label: 'Active',
    color: '#3eb002',
  },
  {
    status: 2,
    label: 'Submitted',
    color: '#fcd103',
  },
  {
    status: 3,
    label: 'Verifying',
    color: '#3eb002',
  },
  {
    status: 4,
    label: 'Information Needed',
    color: '#fcd103',
  },
  {
    status: 5,
    label: 'Rejected',
    color: '#b00211',
  },
  {
    status: 11,
    label: 'In Review',
    color: '#e25f03',
  },
  {
    status: 33,
    label: 'Closed - Pending',
    color: '#c4c8c2',
  },
  {
    status: 66,
    label: 'Pending',
    color: '#fcd103',
  },
  {
    status: 99,
    label: 'Denied',
    color: '#b00211',
  },
  {
    status: 133,
    label: 'Closed',
    color: '#c4c8c2',
  },
]

export const HISTORY_TYPES = {
  beneficial_owner_officer: {
    group_title: 'Beneficial Owner/Officer Contact Change',
  },
  primary_contact: {
    group_title: 'Primary Contact Change',
  },
  dispute: {
    group_title: 'Dispute Contact Change',
  },
  technical: {
    group_title: 'Technical Contact Change',
  },
  merchants: {
    group_title: 'General Account Change',
  },
  merchants_business_address: {
    group_title: 'General Change',
  },
  merchants_mailing_address: {
    group_title: 'General Change',
  },
  merchants_status: {
    group_title: 'General Change',
  },
  merchants_account_information: {
    group_title: 'General Change',
  },
  merchants_contact_information: {
    group_title: 'Contact Information Change',
  },
  merchants_contact_address: {
    group_title: 'Contact Information Change',
  },
  merchants_tier: {
    group_title: 'Miscellaneous Change',
  },
  merchants_pos: {
    group_title: 'Miscellaneous Change',
  },
  merchants_dual_refunds: {
    group_title: 'Miscellaneous Change',
  },
  merchants_allow_refunds_and_voids: {
    group_title: 'Miscellaneous Change',
  },
  merchants_product: {
    group_title: 'Miscellaneous Change',
  },
  cta: {
    group_title: 'Miscellaneous Change',
  },
  merchants_bank_account: {
    group_title: 'Bank Account Change',
  },
  merchants_limits: {
    group_title: 'Limits Change',
  },
  merchants_create: {
    group_title: 'Creation',
  },
  merchants_processor: {
    group_title: 'Processor',
  },
  merchants_payout_frequency: {
    group_title: 'Deposit Frequency Change'
  },
  merchants_funding_hold: {
    group_title: 'Funding Hold'
  }
}

export const HISTORY_FILTERS = [
  {
    label: 'All',
    value: '',
    noResults: 'No change history found.',
  },
  {
    label: 'General',
    value: 'general',
    noResults: 'No general/account history changes found.',
  },
  {
    label: 'Limits',
    value: 'limits',
    noResults: 'No limit change history found.',
  },
  {
    label: 'Banking',
    value: 'banking',
    noResults: 'No bank account change history found.',
  },
  {
    label: 'Contact',
    value: 'contact',
    noResults: 'No contact information change history found.',
  },
  {
    label: 'Miscellaneous',
    value: 'misc',
    noResults: 'No miscellaneous change history found.',
  },
  {
    label: 'Processor',
    value: 'processor',
    noResults: 'No processor change history found.',
  },
  {
    label: 'Funding Hold',
    value: 'funding_hold',
    noResults: 'No funding hold history found.',
  },
]

export const HISTORY_FIELDS = {
  bank_account_number_last_four: 'Bank Account Number (Last 4)',
  bank_account_ownership_type: 'Bank Account Ownsership Type',
  bank_account_type: 'Bank Account Type',
  bank_name: 'Bank Name',
  bank_routing_number: 'Bank Routing Number',
  business_address_1: 'Business Street Address',
  business_address_2: 'Business Street Address (Line 2)',
  business_city: 'Business Address City',
  business_common_name: 'Business Common Name (DBA)',
  business_country: 'Business Address Country',
  business_legal_name: 'Business Legal Name',
  business_state: 'Business Address State',
  business_zip: 'Business Address Zip/Postal Code',
  business_phone: 'Business Phone',
  business_url: 'Business Url',
  business_ein: 'Business EIN/SSN',
  business_ssn_last_4: 'Business SSN Last 4',
  contact_address_1: 'Contact Street Address',
  contact_address_2: 'Contact Street Address (Line 2)',
  contact_city: 'Contact Address City',
  contact_country: 'Contact Address Country',
  contact_email: 'Contact Email',
  contact_first_name: 'Contact First Name',
  contact_last_name: 'Contact Last Name',
  contact_phone_mobile: 'Contact Mobile Phone Number',
  contact_phone_other: 'Contact Phone Number',
  contact_state: 'Contact Address State',
  contact_zip: 'Contact Address Zip/Postal Code',
  contact_ssn_last_4: 'Contact SSN',
  contact_date_of_birth: 'Contact Date of Birth',
  client_name: 'Product Name',
  id: 'Merchant ID',
  mailing_address_1: 'Mailing Street Address',
  mailing_address_2: 'Mailing Street Address (Line 2)',
  mailing_city: 'Mailing Address City',
  mailing_country: 'Mailing Address Country',
  mailing_state: 'Mailing Address State',
  mailing_zip: 'Mailing Address Zip/Postal Code',
  reason: 'Account Closure Reason',
  reject_invalid_cvv: 'Account Setting: Reject Invalid CVV',
  status: 'Merchant Status',
  tier: 'Merchant Web Rate/Tier',
  enable_pos_devices: 'POS Devices Updated',
  enable_dual_refunds: 'Dual Processor Refunds Updated',
  allow_refunds_and_voids: 'Permission: Allow Refunds and Voids',
  rate_id: 'Merchant Rate ID',
  gateway_contact_id: 'Gateway Contact ID',
  processor_id: 'Processor',
  gateway_id: 'Gateway ID',
  gateway_alt_id: 'Gateway Alt ID',
  payout_frequency: 'Payout Intervals',
  payout_frequency_anchor: 'Payout Day',
  description: 'Description',
  balance: 'Balance',
  type: 'Type',
  cc_per_transaction_limit: 'Credit Card Per Item Limit',
  cc_per_month_limit: 'Credit Card Per Month Limit',
  ach_per_transaction_limit: 'ACH Per Item Limit',
  ach_per_month_limit: 'ACH Per Month Limit',
  cc_refund_line_of_credit: 'Refund Limit',
  has_funding_hold: 'Has Funding Hold',
  client_id: 'Client Id',
  mobile_rate: 'Merchant Mobile Rate/Tier',
  ssn_encrypted: 'SSN (Encryption Change)',
  country_id: 'Country',
  name: 'Business Name',
  contact_email_unique: 'Unique Contact Email',
  business_type: 'Structure',
  bank_routing_number_last_four: 'Bank Routing Number (Last 4)',
  amount_range: 'Amount Range',
  international_document_type: 'International Document Type',
  international_document_number: 'International Document Number',
  international_document_exp_date: 'International Document Expiration Date',
  international_document_issuing_state: 'International Document Issuing State',
  australia_medicare_number: 'Australia Medicare Number',
  australia_medicare_color: 'Australia Medicare Color',
  co_owners: 'Co-Owners',
  funding_holds: 'Funding Holds Array',
  hold_first_reported_date: 'Hold First Reported Date',
  hold_last_removed_date: 'Hold Last Removed Date',
  business_mcc: 'Business MCC',
  bank_account_number_encrypted: 'Bank Account Number (Encryption Change)',
  debit_negative_balances: 'Debit Negative Balances',
  available_balance: 'Available Balance',
  pending_balance: 'Pending Balance',
  funding_holds_desc: 'Funding Holds Description',
  funding_hold_set_by_email: 'Funding Hold Set By',
  product_description: 'Product Description',
  gateway_location_id: 'Gateway Location ID',
  last_modified_by_remote_id: 'Last Modified By Remote ID',
  stack_works_disputes: 'Stack Works Disputes',
  mobile_rate_id: 'Mobile Rate ID',
  created_cta: 'Assigned CTA',
  reactivated_cta: 'Reassigned CTA',
  deleted_cta: 'Unassigned CTA',
  completed_cta: 'Completed CTA',
  tax_id_status: 'Tax ID Status',
  tax_id_status_definition: 'Tax ID Status Definition',
  allow_checkout: 'Allow Checkout',
  allow_customers: 'Allow Customers',
  allow_subscriptions: 'Allow Subscriptions',
  small_dollar_transaction: 'Small Dollar Transaction',
  small_dollar_transaction_amount: 'Small Dollar Transaction Amount',
  small_dollar_transaction_rate_id: 'Small Dollar Transaction Rate Id',
  stack_owned: 'Stack Owned Account',
}

export const ALERT_TYPES = {
  information: {
    color: '#0049B3',
    colorName: 'blue',
    icon: 'info_outline',
  },
  notice: {
    color: '#C79C10',
    colorName: 'yellow',
    icon: 'info_outline',
  },
  warning: {
    color: '#C79C10',
    colorName: 'yellow',
    icon: 'warning_amber',
  },
  error: {
    color: '#C71818',
    colorName: 'red',
    icon: 'error_outline',
  }
}
